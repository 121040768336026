import { AxiosPromise } from 'axios'
import { http, clientBase } from '@/api/requests/httpAxios'
import { BuildInfo } from 'interfaces/logs'

export default class InfoAPI {
  public static getServerBuildInfo(): AxiosPromise<BuildInfo> {
    return http.get(`/_build.json`)
  }

  public static getClientBuildInfo(): AxiosPromise<BuildInfo> {
    return http.get(`/_build.json`, {
      baseURL: clientBase
    })
  }

  public static getClientBuildFromServer(): AxiosPromise<BuildInfo> {
    return http.get('/client-version')
  }
}
