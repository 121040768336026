














import { Component, Vue } from 'vue-property-decorator'
import BuildInfo from '@/components/ui/BuildInfo.vue'
import SendNotifications from '@/components/admin/SendNotifications.vue'
import MainCard from '@/components/ui/MainCard.vue'
import AdminAPI from '@/api/requests/admin'

@Component({ components: { MainCard, BuildInfo, SendNotifications } })
export default class BuildInfoView extends Vue {
  private async downloadDump() {
    await AdminAPI.downloadDump()
  }
}
