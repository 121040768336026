
































import { Component, Vue } from 'vue-property-decorator'
import { BModal } from 'bootstrap-vue'
import { FormOptions, FormSchema } from 'vue-form-generator'
import validators from '@/utils/validators'
import moment from 'moment'

import AdminAPI from '@/api/requests/admin'
import dateFormat from '@/config/default/dateFormat'
import adminNotification from '@/factories/adminNotification'

@Component
export default class SendNotifications extends Vue {
  $refs!: { modal: BModal }
  private model = adminNotification.systemNotification()
  private messageType: 'maintenance' | 'reload' = 'maintenance'

  private isFormValid = false

  private maintenanceSchema: FormSchema = {
    fields: [
      {
        type: 'CKEditor',
        label: 'Сообщение',
        model: 'message',
        validator: validators.required,
        required: true
      },
      {
        type: 'datetimePicker',
        model: 'time',
        label: 'Время',
        validator: validators.required,
        required: true,
        buttons: [
          {
            label: 'Стандартное сообщение',
            onclick(model: any) {
              if (model.time) {
                model.message = `На <b>${moment(model.time).format(
                  dateFormat.date.dateTime
                )}</b> запланировано обслуживание системы. Чтобы избежать потери данных, пожалуйста, завершите работу с системой к этому времени.`
              } else {
                model.message = ''
              }
            }
          }
        ]
      }
    ]
  }

  private reloadSchema: FormSchema = {
    fields: [
      {
        type: 'CKEditor',
        label: 'Сообщение',
        model: 'message',
        validator: validators.required,
        required: true
      },
      {
        type: 'checkbox',
        label: 'Выключатель перезагрузки',
        model: 'showReload',
        validator: validators.required,
        required: true
      }
    ]
  }

  private get schema() {
    switch (this.messageType) {
      case 'maintenance':
        return this.maintenanceSchema
      case 'reload':
        return this.reloadSchema
    }
    return {}
  }

  private get title() {
    switch (this.messageType) {
      case 'maintenance':
        return 'Сообщение об обслуживании системы'
      case 'reload':
        return 'Сообщение о перезагрузке'
    }
    return ''
  }

  private created() {
    this.model = adminNotification.systemNotification()
  }

  private formOptions: FormOptions = {
    validateAfterLoad: true,
    validateAfterChanged: true
  }

  private onMaintenance() {
    this.model = adminNotification.systemNotification()
    this.messageType = 'maintenance'
    this.$refs.modal.show()
  }

  private async onMaintenanceDown() {
    await AdminAPI.clearMessage()
  }

  private onNotify() {
    this.model = adminNotification.systemNotification()
    this.messageType = 'reload'
    this.model.message =
      'Было проведено обновление системы. Пожалуйста, обновите страницу, чтобы получить последнюю версию приложения'
    this.model.showReload = true
    this.$refs.modal.show()
  }

  private onFormValidated(isValid: boolean) {
    this.isFormValid = isValid
  }

  private async send() {
    switch (this.messageType) {
      case 'maintenance':
        await AdminAPI.setMessage(
          this.model.message,
          this.model.time,
          this.model.showReload,
          true
        )
        break
      case 'reload':
        await AdminAPI.setMessage(
          this.model.message,
          this.model.time,
          this.model.showReload,
          false
        )
        break
    }
  }
}
