

















































import { Component, Vue } from 'vue-property-decorator'
import InfoAPI from '@/api/requests/info'
import { BuildInfo } from '@/types/interfaces/logs'
import { mobileWidthMapper } from '@/store/modules/mobileWidth'

const Mappers = Vue.extend({
  computed: {
    ...mobileWidthMapper.mapGetters(['isMobile'])
  }
})

@Component
export default class BuildInfoComponent extends Mappers {
  private isLoading = true
  private serverInfo: BuildInfo | null = null
  private clientInfo: BuildInfo | null = null

  private async created() {
    try {
      this.serverInfo = (await InfoAPI.getServerBuildInfo()).data
    } catch (err) {
      // console.error(err)
    }
    try {
      this.clientInfo = (await InfoAPI.getClientBuildInfo()).data
    } catch (err) {
      // console.error(err)
    }
    this.isLoading = false
  }
}
